<template>
  <v-row justify="center">
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      persistent
      max-width="1000px"
      transition="fade-transition"
    >
      <v-card>
        <v-btn
          icon
          color="error"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-form
          ref="form"
          v-model="isValid"
        >
          <v-card-title>
            <span class="text-h5">Cadastro de Cliente</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="form.data.enabled"
                    :label="`Status: ${checkboxChecker(form.data.enabled)}`"
                    color="success"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="form.data._id"
                    :label="`ID`"
                    outlined
                    readonly
                  />
                </v-col>
              </v-row>

              <div class="py-3" />

              <h4>Dados Gerais</h4>

              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="form.data.name"
                    :rules="[rules.noCoding, rules.exists]"
                    required
                    label="Nome:"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="form.data.code"
                    :rules="[rules.noCoding, rules.exists]"
                    required
                    label="Código:"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.data.corporateName"
                    :rules="[rules.noCoding]"
                    label="Corporação:"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="form.data.cep"
                    v-mask="'#####-###'"
                    :rules="[rules.noCoding]"
                    label="CEP:"
                    append-icon="mdi-map-marker"
                    counter="9"
                    @click:append="form.getAddressByCEP()"
                    @blur="form.getAddressByCEP()"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="form.data.state"
                    :rules="[rules.noCoding]"
                    label="Estado:"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="form.data.city"
                    :rules="[rules.noCoding]"
                    label="Cidade:"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.data.address"
                    :rules="[rules.noCoding, rules.exists]"
                    required
                    label="Endereço:"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="form.data.phoneNumber"
                    v-mask="['(##) # ####-####', '(##)  ####-####']"
                    :rules="[rules.noCoding]"
                    label="Telefone:"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="form.data.cnpj"
                    v-mask="'##.###.###/####-##'"
                    label="CNPJ:"
                    counter="18"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="form.data.createdAt"
                    v-mask="'####-##-##'"
                    readonly
                    label="Data de criação:"
                  />
                </v-col>
              </v-row>

              <div class="py-3" />

              <v-row
                align="center"
                justify="space-between"
              >
                <h4 class="ml-3">
                  Contatos
                </h4>
                <v-btn
                  color="success"
                  text
                  @click="addContact()"
                >
                  Adicionar Contato
                </v-btn>
              </v-row>

              <div
                v-for="(contact, index) in form.data.contacts"
                :key="index"
              >
                <div class="py-2" />

                Contato {{ index + 1 }}
                <br>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="contact.name"
                      :rules="[rules.noCoding]"
                      label="Nome:"
                      @input="(val) => form.data.contacts[index].name = val"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="contact.phoneNumber"
                      v-mask="['(##) # ####-####', '(##)  ####-####']"
                      :rules="[rules.noCoding]"
                      label="Telefone:"
                      @input="(val) => form.data.contacts[index].phoneNumber = val"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="contact.mobile"
                      v-mask="['(##) # ####-####', '(##)  ####-####']"
                      :rules="[rules.noCoding]"
                      label="Celular:"
                      @input="(val) => form.data.contacts[index].mobile = val"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="contact.email"
                      :rules="[rules.noCoding, rules.email, rules.exists]"
                      label="E-mail:"
                      @input="(val) => form.data.contacts[index].email = val"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="text-end"
                  >
                    <v-btn
                      color="error"
                      text
                      @click="deleteContact(index)"
                    >
                      Deletar Contato
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <div class="py-3" />

              <h4>Serviços</h4>

              <service-box
                v-for="(serviceName, index) in Object.keys(form.data.services)"
                :key="`${serviceName}:${index}`"
                v-model="form.data.services[serviceName]"
                :name="serviceName"
              />

              <v-row
                style="left: 100px;"
                justify="start"
              >
                <v-col cols="6">
                  <h4>
                    Tentacle
                  </h4>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-checkbox
                    v-model="form.data.verifSMS"
                    :label="`SMS Verify: ${checkboxChecker(form.data.verifSMS)}`"
                    color="success"
                  />
                </v-col>
                <v-col cols="6">
                  <v-checkbox
                    v-model="form.data.updateDomain"
                    :label="`Update Domain: ${checkboxChecker(form.data.updateDomain)}`"
                    color="success"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.data.tentacle.version"
                    :rules="[rules.noCoding]"
                    label="Versão:"
                    @input="(val)=>typingTentacle({ version: val })"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.data.domain"
                    :rules="!genDomain ? [rules.noCoding,rules.exists,rules.domainFormat] : [rules.noCoding,rules.domainFormat]"
                    required
                    label="Domínio:"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.data.tentacle.jwtSecret"
                    :rules="[rules.noCoding]"
                    label="JWT Secret:"
                    @input="(val)=>typingTentacle({ jwtSecret: val })"
                  />
                </v-col>
                <v-col cols="6">
                  <v-combobox
                    v-model="form.data.tentacle.bucketAWS"
                    cache-items
                    label="Bucket AWS"
                    :items="bucketList"
                    item-text="Name"
                    return-object
                  />
                </v-col>
              </v-row>

              <div class="py-3" />

              <v-row
                style="left: 100px;"
                justify="start"
              >
                <v-col cols="6">
                  <h4>
                    Database
                  </h4>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.data.connectionDB.host"
                    class="px-3 py-3"
                    label="Host:"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.data.connectionDB.user"
                    class="px-3 py-3"
                    label="Usuário:"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.data.connectionDB.password"
                    class="px-3 py-3"
                    label="Senha:"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.data.connectionDB.database"
                    class="px-3 py-3"
                    label="Database:"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.data.connectionDB.port"
                    class="px-3 py-3"
                    label="Port:"
                  />
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="form.data.connectionDB.socketHost"
                    class="px-3 py-3"
                    label="Socket Host:"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="form.data.connectionDB.socketPort"
                    class="px-3 py-3"
                    label="Socket Port:"
                  />
                </v-col>
              </v-row>

              <div class="py-3" />

              <v-row
                style="left: 100px;"
                justify="start"
              >
                <v-col cols="6">
                  <h4>
                    Túnel
                  </h4>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-checkbox
                    v-model="form.data.genDomain"
                    :label="`Registrar domínio NGROK: ${checkboxChecker(form.data.genDomain)}`"
                    color="success"
                  />
                </v-col>
                <v-col cols="6">
                  <v-checkbox
                    v-model="form.data.genToken"
                    :label="`Gerar Token NGROK: ${checkboxChecker(form.data.genToken)}`"
                    color="success"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="form.data.tunnelConfig.url"
                    :rules="[rules.noCoding]"
                    label="Subdomínio:"
                    @input="(val)=>typingTunnelConfig({ url: val })"
                  />
                </v-col>
                <v-col v-if="!genToken">
                  <v-text-field
                    v-model="form.data.tunnelConfig.credencial"
                    :rules="[rules.noCoding]"
                    label="Credencial:"
                    @input="(val)=>typingTunnelConfig({ credencial: val })"
                  />
                </v-col>
              </v-row>

              <v-row
                style="left: 100px;"
                justify="start"
              >
                <v-col cols="6">
                  <h4>
                    Modulos e Versionamento
                  </h4>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-checkbox
                    v-model="form.data.localModules.autoUpdate"
                    label="Habilitar Atualização Automática"
                    color="success"
                  />
                </v-col>
                <v-col cols="6">
                  <v-checkbox
                    v-model="form.data.localModules.downgradeAllowed"
                    label="Permitir Downgrade"
                    color="success"
                  />
                </v-col>

                <v-row
                  v-for="(module, index) in form.data.localModules.versions"
                  :key="`moduleVersionI:${index}`"
                >
                  <v-col>
                    <v-text-field
                      v-model="module.name"
                      label="Sistema"
                      disabled
                    />
                  </v-col>
                  <v-col v-if="!genToken">
                    <v-text-field
                      v-model="module.desiredVersion"
                      :rules="[rules.noCoding]"
                      label="Versão Desejada"
                    />
                  </v-col>
                </v-row>
              </v-row>
            </v-container>
          </v-card-text>

          <!-- Actions -->
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              text
              @click="dialog = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="success"
              text
              @click="send()"
            >
              Enviar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { mask } from 'vue-the-mask'
  import { mapActions, mapGetters } from 'vuex'
  import axiosSync from '../../../../../axios-SYNC'
  import rules from '../../../../services/rules'
  import FormData from './formData'

  export default {
    name: 'CustomerForm',
    components: {
      serviceBox: () => import('./ServiceBox'),
    },
    directives: {
      mask,
    },
    data: () => {
      return {
        dialog: false,

        form: null,

        dateFormat: '####-##-##',
        isValid: false,
        rules: rules,

        bucketList: [],
        genDomain: false,
        genToken: false,
        domain: '',
        token: '',
      }
    },
    computed: {
      ...mapGetters('customers', ['customerList']),
    },
    mounted () {
      this.getBuckets()
    },
    methods: {
      ...mapActions('customers', ['sendCustomerForm', 'getCustomer', 'generateAuthNgrok', 'generateReservedDomain']),
      async openForm (customerId, clone) {
        let customer = {}
        if (customerId) {
          customer = await this.getCustomer(customerId)
        }
        this.form = new FormData(customer, clone)

        this.dialog = true
      },
      checkboxChecker (value) {
        return value ? 'Habilitado' : 'Desabilitado'
      },

      deleteContact (value) {
        this.form.data.contacts.splice(value, 1)
      },
      addContact (val) {
        if (val) {
          this.form.data.contacts.push(JSON.parse(JSON.stringify(val)))
        } else {
          this.form.data.contacts.push(JSON.parse(JSON.stringify({
            name: '',
            phoneNumber: '',
            mobile: '',
            email: '',
          })))
        }
      },

      async checkDomainGeneration () {
        const user = JSON.parse(localStorage.getItem('user'))
        if (this.genDomain && this.form.data.tunnelConfig.url !== '') {
          const res = await this.generateReservedDomain({ user: user.name, name: this.form.data.name, subdomain: this.form.data.tunnelConfig.url })
          this.domain = 'https://' + res.domain
        }
        if (this.genToken) {
          const res = await this.generateAuthNgrok({ user: user.name, name: this.form.data.name })
          this.token = res.token
        }
      },

      async getBuckets () {
        try {
          const response = await axiosSync.get('/list/buckets')
          this.bucketList = response.data.Buckets
        } catch (err) {
          console.error('Erro buscando buckets', err)
        }
      },

      async send () {
        if (!this.$refs.form.validate()) {
          this.$alert('error', 'Existem campos obrigatórios não preenchidos.')
          return
        }

        await this.checkDomainGeneration()
        const customerData = await this.form.formatDataForServer()

        await this.sendCustomerForm(customerData)
          .then(() => {
            this.dialog = false
            this.$alert('success')
          })
          .catch((err) => {
            this.$alert('error', err.response)
          })
        this.$emit('update')
      },
    },
  }
</script>
