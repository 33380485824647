import axios from 'axios'
import moment from 'moment'

const parseDate = (date) => {
  if (!date) {
    return null
  }
  return `${date.match(/\d{4}-\d{2}-\d{2}/)}T12:00:00-03:00`
}

const getAddress = axios.create({
  baseURL: 'https://viacep.com.br/ws/',
})

export default class CustomerForm {
  clone = null

  data = {
    _id: null,
    authTokenNgrok: '',
    name: '',
    code: '',
    corporateName: '',
    cnpj: '',
    ie: '',
    im: '',
    address: '',
    cep: '',
    city: '',
    state: '',
    enabled: true,
    createdAt: null,
    updateAt: null,
    domain: '',
    phoneNumber: '',
    contacts: [{
      name: '',
      phoneNumber: '',
      mobile: '',
      email: '',
    }],
    tentacle: {
      runMode: '',
      version: '',
      bucketAWS: '',
      jwtSecret: '',
      verifSMS: false,
      updateDomain: false,
    },
    services: {
      clinicWeb: {
        customName: 'Clinic Web',
        enabled: false,
        settings: {
          clinicWeb_medicalRecord_preset: '0',
          clinicWeb_medicalRecord_showDuration: '0',
          clinicWeb_medicalRecord_useMemed: '1',
          clinicWeb_printables_headerShowName: '1',
          clinicWeb_printables_headerShowLogo: '1',
          clinicWeb_printables_logoPosition: 'end', // start, center, end
          clinicWeb_printables_footerPosition: 'start', // start, center, end
          clinicWeb_printables_footerText: '',
          clinicWeb_printables_margins: '',
          clinicWeb_printables_pageSize: '',
          clinicWeb_printables_showBoardLabel: '1',
          clinicWeb_printables_showBoard: '1',
          clinicWeb_schedule_showPatientArrival: '1',
          clinicWeb_schedule_showPatientWaiting: '1',
        },
        printable: {
          logo: '',
          theme: '',
        },
      },
      portal: {
        customName: 'Portal',
        enabled: false,
        logo: '',
      },
      myClinic: {
        customName: 'Meu Clinic',
        enabled: false,
        createdAt: null,
        updateAt: null,
        limitUser: '',
        theme: '',
        colorHTML: '',
        logo: '',
      },
      scheduleOnline: {
        customName: 'Agendamento Online',
        enabled: false,
        createdAt: null,
        updateAt: null,
        theme: '',
        colorHTML: '',
        logo: '',
        cssClass: '',
      },
      virtualClin: {
        customName: 'VirtualClin',
        enabled: false,
        createdAt: null,
        updateAt: null,
      },
      apiRisc: {
        customName: 'API Risc',
        enabled: false,
        createdAt: null,
        updateAt: null,
      },
      doctoralia: {
        customName: 'Doctoralia',
        enabled: false,
        createdAt: null,
        updateAt: null,
      },
      chatBot: {
        customName: 'ChatBot',
        enabled: false,
        createdAt: null,
        updateAt: null,
      },
      docPlanner: {
        customName: 'Docplanner',
        enabled: false,
        createdAt: null,
        updateAt: null,
      },
      webCheckIn: {
        customName: 'Web-Check-In',
        logo: '',
        enabled: false,
        createdAt: null,
        updateAt: null,
      },
      lanac: {
        customName: 'Lanac',
        enabled: false,
        createdAt: null,
        updateAt: null,
      },
    },
    tunnelConfig: {
      url: '',
      credencial: '',
    },
    connectionDB: {
      host: '',
      user: '',
      database: '',
      port: '',
      password: '',
      socketHost: '',
      socketPort: '',
    },
    localModules: {
      autoUpdate: false,
      downgradeAllowed: false,
      versions: [],
    },
  }

  constructor (customerData = {}, clone = false) {
    if (customerData.services) {
      Object.keys(customerData.services).map(serviceKey => {
        if (!this.data.services[serviceKey]) this.data.services[serviceKey] = {}

        if (serviceKey === 'clinicWeb') {
          const filledClinicWebSettings = Object.assign(this.data.services[serviceKey].settings, customerData.services[serviceKey].settings)
          customerData.services[serviceKey].settings = filledClinicWebSettings
        }

        console.log('serviceKey', serviceKey, customerData.services[serviceKey], this.data.services[serviceKey])
        const newObj = Object.assign(this.data.services[serviceKey], customerData.services[serviceKey])

        console.log('newObj', newObj)

        delete customerData.services[serviceKey]
      })
      delete customerData.services
    }
    Object.assign(this.data, customerData)

    this.clone = clone

    if (customerData && clone) {
      this.data._id = null
      this.data.createdAt = null
      this.data.updateAt = null
      Object.keys(this.data.services).map(key => {
        this.data.services[key].createdAt = null
        this.data.services[key].updateAt = null
      })
    }
  }

  formatDataForServer () {
    const data = JSON.parse(JSON.stringify(this.data))

    data.phoneNumber = data.phoneNumber ? data.phoneNumber.replace(/[-+()\s]/g, '') : null
    data.cep = data.cep ? data.cep.replace(/[-]/g, '') : null

    data.contacts.forEach((contact) => {
      contact.mobile = contact.mobile ? contact.mobile.replace(/[-+() \s]/g, '') : null
      contact.phoneNumber = contact.phoneNumber ? contact.phoneNumber.replace(/[-+() \s]/g, '') : null
    })
    data.contacts.filter(contact => {
      return (
        (contact.name && contact.name !== '') &&
        (contact.email && contact.email !== '') &&
        (contact.mobile && contact.mobile !== '') &&
        (contact.phoneNumber && contact.phoneNumber !== '')
      )
    })

    data.updateAt = parseDate(data.updateAt) || moment().toISOString()
    data.createdAt = parseDate(data.createdAt) || moment().toISOString()
    Object.keys(data.services).map(key => {
      data.services[key].updateAt = parseDate(data.services[key].updateAt) || moment().toISOString()
      data.services[key].createdAt = parseDate(data.services[key].createdAt) || moment().toISOString()
    })

    data.tentacle.bucketAWS = data.tentacle.bucketAWS && data.tentacle.bucketAWS.Name ? data.tentacle.bucketAWS.Name : null

    return data
  }

  getAddressByCEP () {
    const { cep } = this.data
    if (!cep) { return }

    const zipCodeClear = cep.match(/([0-9]+)/g).join('')
    if (!zipCodeClear || zipCodeClear.length !== 8) {
      return
    }

    getAddress.get(`${zipCodeClear}/json/`)
      .then((response) => {
        if (response.data.erro) {
          this.$alert('CEP não encontrado.')
          return
        }
        const address = response.data

        this.data.address = address.logradouro + ', ' + address.bairro
        this.data.city = address.localidade
        this.data.state = address.uf
      })
      .catch((err) => {
        this.$alert('CEP não encontrado.')
        console.error(err)
      })
  }
}
